/* @import url(http://fonts.googleapis.com/earlyaccess/droidarabickufi.css); */
/* @import url('/fonts/bahij.ttf');
@import url('/fonts/bold.otf'); */

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700;900&display=swap');
@font-face {
  font-family: 'bahij';
  /* src: url('/fonts/bahij/bahij.ttf'); */
  src: url('/src/fonts/bahij/bahij.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'diodrum-med';
  src: url('/src/fonts/DiodrumArabic-Medium.ttf');
  src: local('diodrum-med'),
    url('/src/fonts/DiodrumArabic-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'diodrum-bold';
  src: local('diodrum-bold'),
    url('/src/fonts/DiodrumArabic-Semibold.ttf') format('truetype');
}
#image-head {
  background-repeat: no-repeat;
  background-size: cover;
}

.publish input {
  color: black;
  border: 1px solid black;
}
.publish select {
  color: black;
  border: 1px solid black;
}
.publish textarea {
  color: black;
  border: 1px solid black;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  /* font-family: 'Droid Arabic Kufi', serif !important; */
  font-family: 'bahij', 'Cairo', sans-serif !important;
  direction: rtl;
  text-align: right !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering: optimizeLegibility !important;
  /* background-color: #f5f2ef; */
  /* overflow-y: hidden !important; */
  /* overflow-x: hidden !important; */
  /* scrollbar-width: none;
  position: relative; */
  /* float: left; */
  min-width: 400px;
}
#root {
  overflow-x: hidden !important;
  /* background-color: #f5f2ef; */
}
body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  background-color: rgb(214, 214, 214);
  box-shadow: inset 0 0 6px rgb(213, 213, 213);
}

body::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-left: 0.5px solid black;
  outline: 1px solid slategrey;
}

/* input::placeholder {
  color: #ddd;
}
input {
  border: 1px solid #eee !important;
  border-color: #eee !important;
} */

.dont-break-out {
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering: optimizeLegibility !important;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.project-chervron {
  font-size: larger !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
p {
  font-family: inherit !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit !important;
}

.detail-image {
  /* Location of the image */

  /* Background image is centered vertically and horizontally at all times */
  background-position: center center !important;

  /* Background image doesn't tile */
  background-repeat: no-repeat !important;

  /* Background image is fixed in the viewport so that it doesn't move when
     the content's height is greater than the image's height */
  /* background-attachment: fixed !important; */

  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover !important;

  /* Set a background color that will be displayed
     while the background image is loading */
  background-color: #464646 !important;
  /* border-bottom-left-radius: 70% 60px;
  border-bottom-right-radius: 30% 10px; */
  width: 70% !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

::selection {
  background: rgb(0, 0, 0);
  color: #fff;
  text-shadow: none;
}
.search-box::placeholder {
  color: black;
}

.rec-dot_active {
  background-color: rgb(255 255 255 / 50%) !important;
  box-shadow: 0 0 1px 3px rgb(255 255 255) !important;
}
.rec-dot {
  box-shadow: 0 0 1px 2px rgb(255 255 255 / 50%) !important;
}
.rec.rec-arrow:hover {
  border-radius: 50%;
  background-color: #ffffff !important;
  color: #000 !important;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #706e6e;
  border-color: #706e6e;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #706e6e;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #706e6e;
  border-color: #706e6e;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #706e6e;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.books__content strong {
  font-weight: 400;
}

.event-body {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.book-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.facebook {
  transition: transform 0.3s ease-out;
  position: relative;
}

.facebook:hover {
  transform: translate(-30px, 0);
}
.twitter {
  transition: transform 0.3s ease-out;
}

.twitter:hover {
  transform: translate(-30px, 0);
}
.youtube {
  transition: transform 0.3s ease-out;
}

.youtube:hover {
  transform: translate(-30px, 0);
}
.linkedin {
  transition: transform 0.3s ease-out;
}

.linkedin:hover {
  transform: translate(-30px, 0);
}
.whatsapp {
  transition: transform 0.3s ease-out;
}

.whatsapp:hover {
  transform: translate(-30px, 0);
}

.quote {
  margin: 0;
  background: #eee;
  padding: 1em;
  border-radius: 1em;
}
.quote figcaption,
.quote blockquote {
  margin: 1em;
}

.a-special:hover {
  text-decoration: underline;
  color: rgb(82, 82, 20);
}

.chakra-alert__icon {
  display: none !important;
}

.chakra-alert {
  text-align: right !important;
  font-size: larger;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.filter-link:focus {
  background-color: black;
  color: white;
}

.filter-link:active {
  background-color: black;
  color: white;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 300px;
  background-color: yellow;
  padding: 50px;
  font-size: 20px;
}

@media only screen and (min-width: 1600px) {
  .main-logo {
    /* width: 500px !important; */
    width: 270px !important;
    margin-right: 50px;
  }
}
@media only screen and (min-width: 1600px) {
  .padding {
    padding-left: 25% !important;
    padding-right: 25% !important;
  }
}
@media only screen and (max-width: 1600px) {
  .padding {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}
@media only screen and (max-width: 600px) {
  .padding {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .booktablist {
    overflow-x: auto;
  }
}

.cb-slideshow,
.cb-slideshow:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
  background-color: #000;
}
.cb-slideshow:after {
  content: '';
  background: transparent url(./images/pattern.png) repeat top left;
}

.cb-slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 36s linear infinite 0s;
}
/* .cb-slideshow li div {
  z-index: 1000;
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 100%;
  text-align: center;
  opacity: 0;
  color: #fff;
  animation: titleAnimation 36s linear infinite 0s;
}
.cb-slideshow li div h3 {
  font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
  font-size: 240px;
  padding: 0;
  line-height: 200px;
} */
/* .cb-slideshow li:nth-child(1) span {
  background-image: url(./images/1.jpg);
}
.cb-slideshow li:nth-child(2) span {
  background-image: url(./images/2.jpg);
  animation-delay: 6s;
}
.cb-slideshow li:nth-child(3) span {
  background-image: url(./images/3.jpg);
  animation-delay: 12s;
}
.cb-slideshow li:nth-child(4) span {
  background-image: url(./images/4.jpg);
  animation-delay: 18s;
}
.cb-slideshow li:nth-child(5) span {
  background-image: url(./images/5.jpg);
  animation-delay: 24s;
}
.cb-slideshow li:nth-child(6) span {
  background-image: url(./images/6.jpg);
  animation-delay: 30s;
} */

/* .cb-slideshow li:nth-child(2) div {
  animation-delay: 6s;
}
.cb-slideshow li:nth-child(3) div {
  animation-delay: 12s;
}
.cb-slideshow li:nth-child(4) div {
  animation-delay: 18s;
}
.cb-slideshow li:nth-child(5) div {
  animation-delay: 24s;
}
.cb-slideshow li:nth-child(6) div {
  animation-delay: 30s;
} */

/* @keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
} */

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    transform: scale(1.05);
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
    transform: scale(1.1) rotate(3deg);
  }
  50% {
    opacity: 0;
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    opacity: 0;
  }
}

/* @keyframes titleAnimation {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  19% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
} */

.topNavbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.book-filter .headroom--unpinned {
  transform: translateY(-270%) !important;
}

.book-filter .headroom--unfixed {
  top: 0 !important;
}

.navbar .headroom--unpinned {
  transform: translateY(100%) !important;
}

.scroll-top.headroom-wrapper {
  height: 0px !important;
}

.scroll-top .headroom--unpinned {
  transform: translateY(235%) !important;
}

@media only screen and (max-device-width: 480px) {
  .filterNavTop {
    margin-top: -91px;
    width: 100%;
    z-index: 99;
    top: 70px;
  }

  .videowrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
  }
  .videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .detail-image {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 480px) {
  .filterNavTop {
    position: fixed;
    width: 100%;
    /*margin-top: -91px;*/
    z-index: 99;
    top: 70px;
  }
}
/* pagination.css */

.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem;
  list-style: none;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  padding: 0.5rem 0.75rem;
  border: 1px solid #dee2e6;
  text-decoration: none;
}

.pagination li.active a {
  color: white;
}

.pagination li a:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
}
